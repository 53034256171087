import { Fragment, FunctionComponent, useState } from "react";
import {
  Redirect,
  RouteComponentProps,
  Router,
  useNavigate,
} from "@reach/router";
import {
  PUBLIC_COOKIE_POLICY,
  LOST_PASSWORD,
  LOST_PASSWORD_LINK,
  PUBLIC_CONTACT,
  PUBLIC_NEW_CONTRIBUTION,
  PUBLIC_NEW_CONTRIBUTION_LINK,
  PUBLIC_TERMS_OF_USE,
  REGISTER,
  REGISTER_LINK,
  PUBLIC_COMPLETE_PROFILE,
  PUBLIC_COMPLETE_PROFILE_LINK,
} from "../../routes/public";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import knaufLogo from "../../assets/img/logo-white-baseline.png";
import NavLink from "../../services/routing/components/NavLink";
import Register from "./Register";
import LostPassword from "./LostPassword";
import PublicNewContribution from "./PublicNewContribution";
import { useTranslation } from "react-i18next";
import { cx } from "@emotion/css";
import PublicFooter from "../../services/ui/elements/PublicFooter";
import CookiePolicy from "../CookiePolicy";
import TermsOfUse from "../TermsOfUse";
import Contact from "../Contact";
import LangSwitch from "../../services/i18n/LangSwitch";
import IconMenu from "../../services/icons/IconMenu";
import CompleteProfile from "./CompleteProfile";
import useAuth from "../../services/auth/hooks/useAuth";
import { useToasts } from "../../services/toast-notifications";
import { AxiosError } from "axios";
import LoginButton from "../../services/auth/LoginButton";

const Public: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation(["contributions", "auth"]);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const { error } = useToasts();
  const navigate = useNavigate();

  const { login } = useAuth();

  return (
    <div className={"v-layout"}>
      <div className="auth-layout">
        <aside className={cx(["sidebar", isSidebarExpanded && " expanded"])}>
          <LangSwitch />
          <nav className="sidebar-nav">
            <NavLink to={PUBLIC_NEW_CONTRIBUTION_LINK}>
              <img src={knaufLogo} alt="" className="logo" />
            </NavLink>
            <ul className="sidebar-menu">
              <li className="menu-item">
                <NavLink to={PUBLIC_NEW_CONTRIBUTION_LINK}>
                  {t("contributions:SUBMIT_CONTRIBUTION")}
                </NavLink>
              </li>
              <li className="menu-item">
                <LoginButton
                  className={"link sidebar-link"}
                  onClick={(auth0AccessToken) =>
                    login({ auth0AccessToken }).catch((err: AxiosError) => {
                      switch (err.response?.status) {
                        case 404:
                          error(t("auth:login.CREDENTIAL_ERROR"));
                          break;
                        case 412:
                          error(t("auth:login.ACCOUNT_NOT_ACTIVATED"));
                          break;
                        case 428:
                          navigate(PUBLIC_COMPLETE_PROFILE_LINK);
                          break;
                        default:
                          error(t("auth:login.AUTH_ERROR"));
                      }
                    })
                  }
                />
              </li>
              <li className="menu-item">
                <NavLink to={REGISTER_LINK}>{t("auth:REGISTER")}</NavLink>
              </li>
              <li className="menu-item">
                <NavLink to={LOST_PASSWORD_LINK}>
                  {t("auth:FORGOTTEN_PASSWORD")}
                </NavLink>
              </li>
            </ul>
            <button
              className="btn-icon btn-icon-m btn-burger"
              onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
            >
              <IconMenu />
            </button>
          </nav>
        </aside>
        <main className="content">
          <div className="container page-content">
            <Router component={Fragment}>
              <Redirect
                from={"/"}
                to={PUBLIC_NEW_CONTRIBUTION_LINK}
                noThrow
                default
              />
              <Register path={REGISTER} />
              <LostPassword path={LOST_PASSWORD} />
              <PublicNewContribution path={PUBLIC_NEW_CONTRIBUTION} />
              <CookiePolicy path={PUBLIC_COOKIE_POLICY} />
              <TermsOfUse path={PUBLIC_TERMS_OF_USE} />
              <Contact path={PUBLIC_CONTACT} />
              <CompleteProfile path={PUBLIC_COMPLETE_PROFILE} />
            </Router>
          </div>
        </main>
      </div>
      <PublicFooter />
    </div>
  );
};

export default withRedirectToPrivate(Public);
